.mobile__pdf__container {
  flex-grow: 1;
  width: 100%;
  position: relative;
}
.mobile__pdf__container__header {
  height: unset;
  padding-block: 4px;
  border: unset;
  font-style: normal;
  font-weight: bold;
  text-align: start;
  padding-left: 20px;
}
.pdfViewer .canvasWrapper {
  border: #ccc solid 1px;
}
.mobile__pdf__container {
  .pdfViewer .page {
    border-image: none;
    margin: 0;
    border: none;
    width: 100% !important;
    display: grid;
    place-content: center;
    margin-bottom: 8px;
    padding-top: 12px;
  }
  #viewerContainer {
    top: 0;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    .pdfViewer {
      // overflow: hidden;
      touch-action: auto !important;
      position: relative;
      z-index: 10;
    }
  }
}
