//custom fontsize for React-Quill
.ql-toolbar.ql-snow {
  border: 1px solid #efefef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.ql-toolbar.ql-snow .ql-formats {
  display: flex;
  gap: 6px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #efefef;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ql-container {
  //   font-family: 'Arial';
  font-size: 12px;
}

.ql-editor {
  height: auto !important;
  min-height: 150px !important;
  margin-bottom: 10px;
  padding: 10px;
}

.ql-editor {
  a {
    text-decoration: underline;
    color: #1677ff !important;
  }
}

.ql-snow .ql-picker.ql-size {
  width: 60px;
}

.ql-snow .ql-picker.ql-header {
  width: 160px;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  height: 200px;
  overflow: scroll;
}

.ql-snow .ql-size .ql-picker-options {
  height: 200px;
  overflow: scroll;
}

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

// override align text
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
