.message-confirm-body p {
  color: #828282;
  font-size: 14px;
  line-height: 21px;
}

.timepicker {
  .ant-picker-content {
    thead {
      display: table-header-group;
    }
  }
}

@media (max-width: 500px) {
  .timepicker .ant-picker-datetime-panel {
    flex-direction: column;
    overflow-y: scroll;
    max-height: 50vh;
  }
}
