.enterprise-ant-tab {
  padding-right: 24px;
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      width: 100%;
    }
  }
  .ant-tabs-nav-more {
    display: none;
  }
}
