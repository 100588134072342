.container {
  :global {
    .ant-select {
      width: 100%;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
    }
  }
}
